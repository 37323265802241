import React, { useState, useEffect } from "react";

const Hero = () => {
  const [typedText, setTypedText] = useState("");
  const strings = [
    "Project Leader | Full stack developer | DevOps specialist | GCP Engineer | AWS architect",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const typingInterval = 100;
    const backspacingInterval = 50;
    const backspacingDelay = 2000;

    let intervalId;

    const type = () => {
      if (isTyping) {
        const currentString = strings[currentIndex];
        const currentLength = typedText.length;

        if (currentLength < currentString.length) {
          setTypedText((prevText) => prevText + currentString[currentLength]);
        } else {
          setIsTyping(false);
          setTimeout(() => {
            setIsTyping(true);
            setTypedText("");
            setCurrentIndex((prevIndex) => (prevIndex + 1) % strings.length);
          }, backspacingDelay);
        }
      } else {
        const currentLength = typedText.length;

        if (currentLength > 0) {
          setTypedText((prevText) => prevText.slice(0, currentLength - 1));
        } else {
          setIsTyping(true);
        }
      }
    };

    intervalId = setInterval(
      type,
      isTyping ? typingInterval : backspacingInterval
    );

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedText, currentIndex, isTyping]);

  const images = ["Sachin Cover.png"];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const cycleImages = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const imageCycleInterval = setInterval(cycleImages, 1000); // Increase the delay here

    return () => clearInterval(imageCycleInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageIndex]);

  return (
    <>
      <section id="hero" className="hero-content">
        <div className="container">
          <div className="hero-container" data-aos="fade-in">
            <h1 className="hero-title">Sachin Suthar</h1>
            <p>
              I'm <span className="typed">{typedText}</span>
            </p>
          </div>
          <div className="image-container">
            {images.map((image, index) => (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                key={index}
                src={`/assets/img/${image}`}
                alt={`Image ${index + 1}`}
                className={`bg-image ${
                  index === currentImageIndex ? "visible" : ""
                }`}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
