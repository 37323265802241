import React from "react";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";
import About from "./About/About";
import Facts from "./Facts/Facts";
import Skills from "./Skills/Skills";
import Resume from "./Resume/Resume";
import Portfolio from "./Portfolio/Portfolio";
import Services from "./Services/Services";
import Testimonials from "./Testimonials/Testimonials";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
// import { TooltipProvider } from "react-tooltip";

const Index = () => {
  return (
    <>
      <Header />
      <main id="main">
        <Hero />
        <About />
        <Facts />
        {/* <TooltipProvider>  */}
        <Skills />
        {/* </TooltipProvider> */}
        <Resume />
        <Portfolio />
        <Services />
        <Testimonials />
        <Contact />
        <Footer />
      </main>
    </>
  );
};

export default Index;
