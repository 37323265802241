import React from "react";
import { Tooltip } from "react-tooltip";
import { CONFIG } from "../../Config";

const Contact = () => {
  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>Contact</h2>
          </div>
          <div className="row" data-aos="fade-in">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p> Surat, India</p>
              </div>
              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <a
                  href={`mailto:${CONFIG.EMAIL}`}
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  <p>{CONFIG.EMAIL}</p>
                </a>
              </div>
              <div className="phone">
                <i className="bi bi-link"></i>
                <h4>Social links:</h4>
              </div>
              <div>
                <div className="ml-10 linkicon">
                  <a
                    href="https://www.linkedin.com/in/sachin-suthar/"
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bi bi-linkedin"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Linkedin"
                    ></i>
                  </a>
                  <a
                    href="https://www.upwork.com/freelancers/sachinsuthar"
                    className="upwork2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/tech/upwork.png"
                      alt="upwork.png"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Upwork"
                    />
                  </a>
                  <a
                    href="https://calendly.com/sachin3elixir"
                    className="calendar"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bi bi-calendar"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Calendly"
                    ></i>
                  </a>
                  <a
                    href={`mailto:${CONFIG.EMAIL}`}
                    className="envelop"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bi bi-envelope"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Mail"
                    ></i>
                  </a>
                </div>
                <Tooltip
                  id="tooltip"
                  place="top"
                  effect="float"
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    color: "#000000",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
