import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the default CSS

const Auction = () => {
    const images = [
        {
            original: "assets/img/portfolio/Auction/AuctionWeb.png",
            thumbnail: "assets/img/portfolio/Auction/AuctionWeb.png",
            originalClass: "slider-image",
            thumbnailClass: "slider-thumbnail",
        }
    ];

    const [isSliderOpen, setIsSliderOpen] = useState(false);

    const handleImageClick = () => {
        setIsSliderOpen(true);
    };

    const handleCloseSlider = () => {
        setIsSliderOpen(false);
    };

    return (
        <div id="portfolio" className="portfolio div-bg">
            <div>
                <img
                    src={images[0].thumbnail}
                    alt="Main"
                    onClick={handleImageClick}
                    style={{
                        cursor: "pointer",
                        borderRadius: "0.5rem 0.5rem 0 0",
                        objectFit: "contain",
                        //aspectRatio: "4/3",
                        width: "300px",
                        maxHeight: "max(20rem, 30vh)",
                    }}
                />
            </div>

            {/* Image Slider */}
            {isSliderOpen && (
                <div
                    className="slider-container"
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <span
                        style={{
                            position: "absolute",
                            top: 20,
                            right: 20,
                            fontSize: 20,
                            cursor: "pointer",
                            color: "#fff",
                        }}
                        onClick={handleCloseSlider}
                    >
                        &times;
                    </span>
                    <div style={{ maxWidth: "80%", maxHeight: "80%" }}>
                        <ImageGallery
                            items={images}
                            showThumbnails={false}
                            showFullscreenButton={false} // Hide the fullscreen button
                            showPlayButton={false} // Hide the play button
                            showBullets={true} // Show navigation bullets
                            renderLeftNav={(onClick) => (
                                <button
                                    type="button"
                                    onClick={onClick}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: 10,
                                        transform: "translateY(-50%)",
                                        zIndex: 1000,
                                        background: "transparent",
                                        border: "none",
                                        outline: "none",
                                        cursor: "pointer",
                                        fontSize: "24px",
                                    }}
                                >
                                    &lt;
                                </button>
                            )}
                            renderRightNav={(onClick) => (
                                <button
                                    type="button"
                                    onClick={onClick}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: 10,
                                        transform: "translateY(-50%)",
                                        zIndex: 1000,
                                        background: "transparent",
                                        border: "none",
                                        outline: "none",
                                        cursor: "pointer",
                                        fontSize: "24px",
                                    }}
                                >
                                    &gt;
                                </button>
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Auction;
