import React, { useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";
import { Tooltip } from "react-tooltip";

const Skills = () => {
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    // Function to animate progress bars when the waypoint is reached
    const animateProgressBars = () => {
      const progressBars = document.querySelectorAll(".progress .progress-bar");
      progressBars.forEach((progressBar) => {
        const percentage = progressBar.getAttribute("aria-valuenow");
        progressBar.style.width = `${percentage}%`;
      });
    };

    if (shouldAnimate) {
      animateProgressBars();
    }
  }, [shouldAnimate]);

  return (
    <>
      <section id="skills" className="skills section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Skills</h2>
            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
          </div>

          <div className="row skills-content">
            <div className="col-lg-6" data-aos="fade-up">
              <div className="progress">
                <span className="skill">
                  NodeJS <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    style={{ width: shouldAnimate ? "100%" : "0%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  ReactJS <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    style={{ width: shouldAnimate ? "100%" : "0%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Javascript <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    style={{ width: shouldAnimate ? "100%" : "0%" }}
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Typescript <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    style={{ width: shouldAnimate ? "100%" : "0%" }}
                  ></div>
                </div>
              </div>

              {/* Other progress bars */}
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div className="progress">
                <span className="skill">
                  {" "}
                  DevOps Specialist <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    style={{ width: shouldAnimate ? "100%" : "0%" }}
                  ></div>
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                  {" "}
                  GCP Engineer <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    style={{ width: shouldAnimate ? "100%" : "0%" }}
                  ></div>
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                  AWS Architect <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    style={{ width: shouldAnimate ? "100%" : "0%" }}
                  ></div>
                </div>
              </div>
            </div>

            {/* Other columns */}
          </div>

          <div className="icons">
            <h2>Technology</h2>
            <div className="techIcons">
              <div className="techIcons-container">
                <img
                  src="/assets/tech/apache.png"
                  alt="apache.png"
                  className="p-2.5"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Apache"
                />
                <img
                  src="/assets/tech/aws.png"
                  alt="aws.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Amazon Web Services"
                />
                <img
                  src="/assets/tech/css-3.png"
                  alt="css-3.png"
                  className="p-2"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Cascading Style Sheet"
                />
                <img
                  src="/assets/tech/database-storage.png"
                  alt="database-storage.png"
                  className="p-2.5"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Database Storage"
                />
                <img
                  src="/assets/tech/firebase.png"
                  alt="firebase.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Firebase"
                />
                <img
                  src="/assets/tech/git.png"
                  alt="git.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Git"
                />
                <img
                  src="/assets/tech/github.png"
                  alt="github.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Github"
                />
                <img
                  src="/assets/tech/google-cloud.png"
                  alt="google-cloud.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Google Cloud"
                />
                <img
                  src="/assets/tech/GraphQl_Logo.svg.png"
                  alt="GraphQl_Logo.svg.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="GraphQl"
                />
                <img
                  src="/assets/tech/jest.png"
                  alt="jest.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Jest"
                />
                <img
                  src="/assets/tech/js.png"
                  alt="js.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Javascript"
                />
                <img
                  src="/assets/tech/mapbox.png"
                  alt="mapbox.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Mapbox"
                />
                <img
                  src="/assets/tech/nextjs.png"
                  alt="nextjs.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="NextJS"
                />
                <img
                  src="/assets/tech/nodejs.png"
                  alt="nodejs.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="NodeJS"
                />
                <img
                  src="/assets/tech/physics.png"
                  alt="reactjs.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="ReactJS"
                />
                <img
                  src="/assets/tech/postgre.png"
                  alt="postgre.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="PostgreSQL"
                />
                <img
                  src="/assets/tech/python.png"
                  alt="python.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Python"
                />
                <img
                  src="/assets/tech/socket.png"
                  alt="socket.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="SocketIO"
                />
                <img
                  src="/assets/tech/sql-server.png"
                  alt="sql-server.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="SQL Server"
                />
                <img
                  src="/assets/tech/stripe.png"
                  alt="stripe.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Stripe"
                />
                <img
                  src="/assets/tech/typescript.png"
                  alt="typescript.png"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="TypeScript"
                />
              </div>
              <Tooltip
                id="my-tooltip"
                place="top"
                effect="float"
                style={{ backgroundColor: "rgb(0, 0, 0)", color: "#ffffff" }}
              />
            </div>
          </div>
        </div>

        {/* Waypoint element */}
        <Waypoint onEnter={() => setShouldAnimate(true)} bottomOffset="80%" />
      </section>
    </>
  );
};

export default Skills;
