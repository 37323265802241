import React from "react";
import AccessMyApp from "./Projects/AccessMyApp";
import AirMarket from "./Projects/AirMarket";
import Astrogeo from "./Projects/Astrogeo";
import Mcgl from "./Projects/Mcgl";
import DrivesAndDetours from "./Projects/DrivesAndDetours";
import Sprive from "./Projects/Sprive";
import AdzSafe from "./Projects/AdzSafe";
import BallToBall from "./Projects/BallToBall";
// import DonationApp1 from "./Projects/DonationApp1";
// import DonationApp2 from "./Projects/DonationApp2";
import Ilmiya from "./Projects/Ilmiya";
import IQuick from "./Projects/IQuick";
import Mirino from "./Projects/Mirino";
import ProfitPro from "./Projects/ProfitPro";
import Queros from "./Projects/Queros";
import Spfb from "./Projects/Spfb";
import Surasole from "./Projects/SuraSole";
import Techon from "./Projects/Techon";
import CyberQuiz from "./Projects/CyberQuiz";
import ThreeElixir from "./Projects/3elixir";
import QuirkPlusWeb from "./Projects/quirkPlus/web";
import QuirkPlusApp from "./Projects/quirkPlus/app";
import SteevWeb from "./Projects/Steev/SteevWeb";
import SteevApp from "./Projects/Steev/SteevApp";
import Auction from "./Projects/Auction";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Portfolio = () => {
  const [sliderActive, setSliderActive] = React.useState(false);
  const portfolioRef = React.useRef(null);
  const sliderRef = React.useRef(null);


  const projects = [
    {
      component: <QuirkPlusWeb />,
      title: "Quirk plus website",
      link: "https://quirkplus.com/",
    },
    {
      component: <QuirkPlusApp />,
      title: "Quirk plus app",
      link: "https://app.quirkplus.com/download",
    },
    {
      component: <Auction />,
      title: "Auction",
      link: "https://admin.rcfaadmin.com/",
    },
    {
      component: <AccessMyApp />,
      title: "Access My app",
      link: "https://play.google.com/store/apps/details?id=com.accesseap",
    },
    {
      component: <AirMarket />,
      title: "Air Market",
      link: "https://airmarket.io/t",
    },
    {
      component: <SteevWeb />,
      title: "Steev website",
      link: "https://steevlive.com/",
    },
    {
      component: <SteevApp />,
      title: "Steev app",
      link: "https://play.google.com/store/apps/details?id=com.steev.live&hl=en_IN&gl=US",
    },

    {
      component: <Astrogeo />,
      title: "Astrogeo",
      link: "https://play.google.com/store/apps/details?id=cx.mccormick.asterogue",
    },
    {
      component: <DrivesAndDetours />,
      title: "Drives and Detours",
      link: "https://www.drivesanddetours.com/",
    },
    { component: <Mcgl />, title: "Mcgl", link: "https://mcglonline.com/auth" },
    {
      component: <Sprive />,
      title: "Sprive",
      link: "https://play.google.com/store/apps/details?id=com.sprive&hl=en_IN&gl=US",
    },
    {
      component: <AdzSafe />,
      title: "AdzSafe",
      link: "https://www.adzsafe.com/",
    },
    {
      component: <BallToBall />,
      title: "Ball TO Ball",
      link: "http://balltoball.in/, https://play.google.com/store/apps/details?id=cx.mccormick.asterogue",
    },
    // {
    //   component: <DonationApp1 />,
    //   title: "Donation App 1",
    //   link: "https://donate.spfb.org/production?cl=SPFB&pg=match_month_2018&amt=60,120,180,300&value=6&large=1000&source=topright",
    // },
    // {
    //   component: <DonationApp2 />,
    //   title: "Donation App 2",
    //   link: "https://donate.spfb.org/production?cl=SPFB&pg=match_month_2018&amt=60,120,180,300&value=6&large=1000&source=topright",
    // },
    { component: <Ilmiya />, title: "Ilmiya", link: "https://Ilmiya.com" },
    { component: <IQuick />, title: "I Quick", link: "http://iquick.co.in/" },
    { component: <Mirino />, title: "Mirino", link: "https://app.mirino.io/" },
    {
      component: <ProfitPro />,
      title: "Profit Pro",
      link: "https://profitpro.io/",
    },
    { component: <Queros />, title: "Queros", link: " https://qureos.com/ " },
    {
      component: <Spfb />,
      title: "SPFB",
      link: " https://donate.spfb.org/production?cl=SPFB&pg=match_month_2018&amt=60,120,180,300&value=6&large=1000&source=topright",
    },
    {
      component: <Surasole />,
      title: "Surasole Medical",
      link: " https://play.google.com/store/apps/details?id=com.surasole.medical&hl=en_IN&gl=US",
    },
    { component: <Techon />, title: "Techon", link: "https://teachon.org " },
    {
      component: <CyberQuiz />,
      title: "Cyber Quiz",
      link: "https://thecyberquiz.com/",
    },
    {
      component: <ThreeElixir />,
      title: "3Elixir",
      link: "https://3elixir.com/",
    },
  ];

  const cardStyle = {
    position: "relative",
    width: "90%",
    height: "300px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    margin: "10px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  const titleStyle = {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    fontSize: "18px",
    fontWeight: "bold",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#fff",
    textAlign: "center",
  };

  const linkStyle = {
    position: "absolute",
    bottom: "50%",
    left: "50%", // Adjust the position of the link to be at the center horizontally
    transform: "translate(-50%, 50%)", // Center the link horizontally and adjust the vertical position
    fontSize: "16px",
    color: "#fff",
    fontWeight: "bold",
    textDecoration: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    background: "#ffc107",
    opacity: 0, // Initially set the opacity to 0 to hide the link
    transition: "opacity 0.3s ease-in-out",
  };

  const cardContainerStyle = {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    background: "#6B6B6B",
  };

  // Function to handle mouse enter and leave events for the card
  const handleMouseEnter = (index) => {
    const linkElement = document.getElementById(`link-${index}`);
    if (linkElement) {
      linkElement.style.opacity = 1; // Set opacity to 1 on mouse enter to display the link
    }
  };

  const handleMouseLeave = (index) => {
    const linkElement = document.getElementById(`link-${index}`);
    if (linkElement) {
      linkElement.style.opacity = 0; // Set opacity back to 0 on mouse leave to hide the link
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of cards to display in each slide
    slidesToScroll: 1,
    autoplay: sliderActive, // Enable autoplay
    autoplaySpeed: 3000, // Set the interval between slides to 3 seconds (3000 milliseconds)
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2, // Number of cards to display in each slide on screens with width <= 992px
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Number of cards to display in each slide on screens with width <= 768px
        },
      },
    ],
  };

  const customDotStyle = {
    position: "relative",
    top: "10px",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    background: "red", // Customize the dot color here
  };

  // Use Intersection Observer to detect section visibility
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setSliderActive(true); // Activate the slider
        } else {
          setSliderActive(false); // Deactivate the slider
        }
      },
      { threshold: 0.5 } // 50% visibility threshold
    );

    if (portfolioRef.current) observer.observe(portfolioRef.current);

    return () => {
      if (portfolioRef.current) observer.unobserve(portfolioRef.current);
    };
  }, []);

  // Ensure slider autoplay refreshes when `sliderActive` changes
  React.useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Start autoplay manually
    }
  }, [sliderActive]);

  // Debounce function to avoid rapid re-triggers
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handleSliderActivation = debounce(() => {
    if (sliderActive && sliderRef.current) {
      sliderRef.current.slickPlay(); // Start autoplay
    } else if (sliderRef.current) {
      sliderRef.current.slickPause(); // Stop autoplay if inactive
    }
  }, 300); // 300ms debounce to avoid frequent triggers

  // Use Intersection Observer to detect visibility changes
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setSliderActive(entry.isIntersecting),
      { threshold: 0.5 } // 50% visibility threshold
    );

    if (portfolioRef.current) observer.observe(portfolioRef.current);

    return () => {
      if (portfolioRef.current) observer.unobserve(portfolioRef.current);
    };
  }, []);

  // Trigger handleSliderActivation whenever sliderActive changes
  React.useEffect(() => {
    handleSliderActivation();
  }, [sliderActive]);

  return (
    <>
      <section id="portfolio" className="portfolio section-bg" ref={portfolioRef}>
        <div className="container">
          <div className="section-title">
            <h2>Portfolio</h2>
            <Slider {...sliderSettings} ref={sliderRef}>
              {projects.map((project, index) => (
                <div key={index} className="col-md-4">
                  <div
                    className="card mb-3"
                    style={cardStyle}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                  >
                    <div style={cardContainerStyle}>
                      <div style={overlayStyle}></div>
                      <div className="embed-responsive embed-responsive-16by9">
                        {project.component}
                      </div>
                      <h5 className="card-title" style={titleStyle}>
                        {project.title}
                      </h5>
                      <a
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={linkStyle}
                        id={`link-${index}`}
                      >
                        Visit
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <style>
              {`
              .slick-dots li button:before {
                content: "";
                ${customDotStyle}
              }
            `}
            </style>
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default Portfolio;
