import "./App.css";
import Index from "./Components/index.jsx";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  return (
    <>
      <Index />
    </>
  );
}

export default App;
