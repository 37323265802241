import React from "react";
import MyCv from "./SachinV5.pdf";
import { CONFIG } from "../../Config";

const Resume = () => {
  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = MyCv;
    downloadLink.download = "Sachin-suthar.pdf";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <section id="resume" className="resume">
        <div className="container">
          <div className="section-title">
            <h2>
              Resume{" "}
              <button className="resume-download" onClick={handleDownload}>
                <i className="bi bi-cloud-arrow-down"></i>
              </button>
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-6" data-aos="fade-up">
              <h3 className="resume-title">Summary</h3>
              <div className="resume-item pb-0 pt-10">
                <h4>Sachin Suthar</h4>
                <p>
                  I am Full Stack Developer with over 10+ years of Experience,
                  speaks Fluent English, am a Quick Learner, out of box thinker,
                  and love creativity.
                </p>
                <ul>
                  <li>Surat, India</li>
                  {/* <li>+91 (832) 003-7878</li> */}
                  <li>{CONFIG.EMAIL}</li>
                </ul>
              </div>

              <h3 className="resume-title">Education</h3>
              <div className="resume-item">
                <h4>B C A</h4>
                <p>
                  <>Kutch University Bhuj –Kutch Aggr</>
                </p>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <h3 className="resume-title">Professional Experience</h3>
              <div className="resume-item">
                <h4>ATI INDIA</h4>
                <p>
                  <>
                    Sr.PHP Developer
                    <br />
                    Work Experience of 3.0 Years{" "}
                  </>
                </p>
              </div>
              <div className="resume-item">
                <h4>TECHNOTECH INDIA</h4>
                <p>
                  <>
                    Sr.PHP Developer
                    <br />
                    Work Experience of 1.7 Years
                  </>
                </p>
              </div>

              <div className="resume-item">
                <h4>TECHNOTECH INDIA</h4>
                <p>
                  <>
                    Full Stack Developer
                    <br />
                    Work Experience of 1.2 Years
                  </>
                </p>
              </div>
              <div className="resume-item">
                <h4>FRELANCER</h4>
                {/* <h5>2017 - 2018</h5> */}
                <p>
                  <>Present - More than 6 years</>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
