import React, { useEffect } from "react";
import { Tilt } from "react-tilt";
import { CONFIG } from "../../Config";

const About = () => {
  useEffect(() => {
    const contentElement = document.querySelector(".about .content");
    contentElement.classList.add("fade-in");
  }, []);

  return (
    <>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>About</h2>
            <p>
              {" "}
              I am Full Stack Developer with over 10+ years of Experience,
              speaks Fluent English, am a Quick Learner, out of box thinker, and
              love creativity. I’ve served over 100 startups bridging multiple
              industries: Medical, Crowdfunding, E-learning, Finance,
              Entertainment, Hospitality, Manufacturing, and Marketing. I have
              strong experience in using the latest approaches including MVC/MVP
              in the browser, and event-driven applications using NodeJS &
              ReactJS.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4" data-aos="fade-right">
              <Tilt className="w-full shadow-xl">
                <img
                  src="assets/img/profile.png"
                  alt=""
                  className="img-fluid"
                  className="imagepath"
                />
              </Tilt>
            </div>
            <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3 className="top-heading">
                Project Leader | Full stack developer | DevOps specialist | GCP
                Engineer | AWS Architect
              </h3>
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Country:</strong>{" "}
                      <Tilt className="w-full shadow-xl">India</Tilt>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Experience:</strong>
                      <span>
                        <Tilt className="w-full shadow-xl">10+ years</Tilt>
                      </span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Languages:</strong>{" "}
                      <span>
                        <Tilt className="w-full shadow-xl">
                          English, Hindi, Gujarati
                        </Tilt>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Email:</strong>
                      <span>
                        <Tilt className="w-full shadow-xl">
                          <a
                            href={`mailto:${CONFIG.EMAIL}`}
                            target="_blank"
                            style={{ color: "white" }}
                            rel="noreferrer"
                          >
                            {CONFIG.EMAIL}
                          </a>
                        </Tilt>
                      </span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Freelance:</strong>{" "}
                      <span>
                        <Tilt className="w-full shadow-xl">Available</Tilt>
                      </span>
                    </li>
                    <li>
                      <i className="bi bi-chevron-right"></i>{" "}
                      <strong>Hours:</strong>{" "}
                      <span>
                        <Tilt className="w-full shadow-xl">45 Hours/Week</Tilt>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="sidetext">
                <ul>
                  <div className="right-0">
                    <li className="animated-li">
                      <i className="bi bi-dot"></i>I am experienced in the
                      entire software development lifecycle and proficient with
                      highly numeric applications.
                    </li>
                    <li className="animated-li">
                      <i className="bi bi-dot"></i>I am skilled in requirement
                      and functional analysis.
                    </li>
                    <li className="animated-li">
                      <i className="bi bi-dot"></i>I am experienced in handling
                      complex numeric applications.
                    </li>
                    <li className="animated-li">
                      <i className="bi bi-dot"></i>Supervising team technology,
                      tools, and techniques.
                    </li>
                    <li className="animated-li">
                      <i className="bi bi-dot"></i>Diligently ensure adherence
                      to development standards, policies, and procedures.
                    </li>
                    <li className="animated-li">
                      <i className="bi bi-dot"></i>Implement effective &
                      efficient strategies to align with management advice.
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            {/* <div className="below gap-6 content"> */}
            <div className="below gap-6">
              <ul>
                <div className="right-0">
                  <li className="animated-li">
                    <i className="bi bi-dot"></i>I do collaborate with team
                    leaders and developers for cohesive software.
                  </li>
                  <li className="animated-li">
                    <i className="bi bi-dot"></i>I ensure that accurate project
                    estimates and meet delivery on-time.
                  </li>
                  <li className="animated-li">
                    <i className="bi bi-dot"></i>I am dedicated to contributing
                    to continuous improvement for self, team, and systems.
                  </li>
                  <li className="animated-li">
                    <i className="bi bi-dot"></i>I am adaptable to meet evolving
                    business needs.
                  </li>
                  <li className="animated-li">
                    <i className="bi bi-dot"></i>I had obtained sufficient
                    business knowledge to contribute to specifying software
                    solutions for business needs.
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
