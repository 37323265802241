import React, { useState, useEffect } from "react";

const testimonialsData = [
  {
    quote: "Amaze with their fantastic solutions to our products.",
    author: "Aditya Pankaj",
    role: "(Co-Founder)",
    company: "Sprive",
    image:
      "https://3elixir.com/production/static/img/content/testmonials/user2.png",
  },
  {
    quote:
      "Awesome job to make product quality & recommended security to our solution.",
    author: "Patil Pratik",
    role: "(Team Leader)",
    company: "Memcorp Immersive",
    image:
      "https://3elixir.com/production/static/img/content/testmonials/user3.png",
  },
  {
    quote:
      "Excellent skilled developer with latest technology like blockchain solutions.",
    author: "Ash Srivastva",
    role: "(Founder)",
    company: "Steev",
    image:
      "https://3elixir.com/production/static/img/content/testmonials/user1.png",
  },
  {
    quote: "Amaze with their fantastic solutions to our products.",
    author: "Jignesh Patel",
    role: "(Project Leader)",
    company: "Airmarket",
    image:
      "https://3elixir.com/production/static/img/content/testmonials/user1.png",
  },
  // Add more testimonials data here
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <section id="testimonials" className="testimonials section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>

          <div className="testimonials-slider">
            <div
              className="testimonial-item"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                {testimonialsData[currentIndex].quote}
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                <br />
                <h3>{testimonialsData[currentIndex].company}</h3>
              </p>
              <img
                src={testimonialsData[1].image}
                alt=""
                className="img-fluid"
                style={{
                  maxWidth: "100px",
                  maxHeight: "100px",
                  display: "block",
                  margin: "0 auto",
                }}
              />
              <h3>{testimonialsData[currentIndex].author}</h3>
              <h4>{testimonialsData[currentIndex].role}</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
