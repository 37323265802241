import React from "react";
// import CountUp from "react-countup";

const Facts = () => {
  return (
    <>
      <section id="facts" className="facts">
        <div className="container">
          <div className="section-title">
            <h2>Facts</h2>
          </div>

          <div className="row no-gutters">
            <div
              className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
              data-aos="fade-up"
            >
              <div className="count-box">
                <i className="bi bi-emoji-smile"></i>
                <span>
                  {/* <CountUp start={0} end={150} duration={20}>
                    {({ countUpRef, start }) => (
                      <span ref={countUpRef}>{start}</span>
                    )}
                  </CountUp>
                  + */}
                </span>
                <span>150+</span>
                <p>
                  <strong>Happy Clients</strong>
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="count-box">
                <i className="bi bi-journal-richtext"></i>
                <span>
                  {/* <CountUp start={0} end={200} duration={20}>
                    {({ countUpRef, start }) => (
                      <span ref={countUpRef}>{start}+</span>
                    )}
                  </CountUp>
                  + */}
                </span>
                <span>200+</span>
                <p>
                  <strong>Projects</strong>
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="count-box">
                <i className="bi bi bi-laptop"></i>
                <span>
                  {/* <CountUp start={0} end={20} duration={20}>
                    {({ countUpRef, start }) => (
                      <span ref={countUpRef}>{start}+</span>
                    )}
                  </CountUp>
                  + */}
                </span>
                <span>20+</span>
                <p>
                  <strong>Technologies</strong>{" "}
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="count-box">
                <i className="bi bi-calendar-check"></i>
                <span>
                  {/* <CountUp start={0} end={6} duration={20}>
                    {({ countUpRef, start }) => (
                      <span ref={countUpRef}>{start}+</span>
                    )}
                  </CountUp>
                  + */}
                </span>
                <span>6+</span>
                <p>
                  <strong>Years</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Facts;
