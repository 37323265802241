import React, { useState } from "react";

const NavMenu = () => {
  return (
    <nav id="mobile-navbar" className="mobile-nav-menu mobile-navbar">
      <ul>
        <li>
          <a href="#hero">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#resume">Resume</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </nav>
  );
};

const MobileNav = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <div className={`mobile-nav ${isMobileNavOpen ? "mobile-nav-active" : ""}`}>
      <i
        id="mobileNavToggle"
        className={`bi ${
          isMobileNavOpen ? "bi-x" : "bi-list"
        } mobile-nav-toggle d-xl-none`}
        onClick={toggleMobileNav}
      ></i>
      <div>{isMobileNavOpen ? <NavMenu /> : null}</div>
    </div>
  );
};

export default MobileNav;
