import React, { useEffect } from "react";
import { Tilt } from "react-tilt";
import { Controller, Scene } from "scrollmagic";
import { TweenMax, Power2 } from "gsap";
import MobileNav from "../MobileNav/MobileNav.js.jsx";
import { Tooltip } from "react-tooltip";

const Header = () => {
  useEffect(() => {
    const controller = new Controller();

    new Scene({
      triggerElement: "#about",
      triggerHook: 0.1,
    })
      .setClassToggle("#header", "header-active")
      .addTo(controller);

    new Scene({
      triggerElement: "#portfolio",
      triggerHook: 0.1,
    })
      .setClassToggle("#header", "header-active")
      .addTo(controller);

    const scene1 = new Scene({
      triggerElement: "#about",
      triggerHook: 0.1,
      duration: "50%",
    }).on("enter", () => {
      TweenMax.fromTo(
        ".profile h1",
        1,
        { opacity: 0, x: -10 },
        { opacity: 1, x: 0, ease: Power2.easeOut }
      );
      TweenMax.fromTo(
        ".profile img",
        1,
        { opacity: 0, transform: "scale(0.9)" },
        { opacity: 1, transform: "scale(1)", ease: Power2.ease }
      );
      TweenMax.fromTo(
        ".label-popup",
        1,
        { opacity: 0, transform: "scale(0.8)" },
        { opacity: 1, transform: "scale(1)", ease: Power2.ease }
      );
      TweenMax.staggerFrom(
        ".social-links a",
        1,
        { opacity: 0, scale: 0.5, rotation: -45 },
        0.2,
        () => {
          TweenMax.set(".social-links a", {
            opacity: 1,
            scale: 1,
            rotation: 0,
          });
        }
      );
    });

    const scene2 = new Scene({
      triggerElement: "#portfolio",
      triggerHook: 0.1,
      duration: "50%",
    }).on("enter", () => {
      TweenMax.fromTo(
        ".profile h1",
        1,
        { opacity: 0, x: -10 },
        { opacity: 1, x: 0, ease: Power2.easeOut }
      );
      TweenMax.fromTo(
        ".profile img",
        1,
        { opacity: 0, transform: "scale(0.9)" },
        { opacity: 1, transform: "scale(1)", ease: Power2.ease }
      );
      TweenMax.fromTo(
        ".label-popup",
        1,
        { opacity: 0, transform: "scale(0.8)" },
        { opacity: 1, transform: "scale(1)", ease: Power2.ease }
      );
      TweenMax.staggerFrom(
        ".social-links a",
        1,
        { opacity: 0, scale: 0.5, rotation: -45 },
        0.2,
        () => {
          TweenMax.set(".social-links a", {
            opacity: 1,
            scale: 1,
            rotation: 0,
          });
        }
      );
    });
    scene1.addTo(controller);
    scene2.addTo(controller);
  }, []);

  return (
    <>
      <MobileNav />
      <header id="header">
        <div className="d-flex flex-column">
          <div className="profile">
            <Tilt className="w-full">
              <img
                src="assets/img/profile.png"
                alt=""
                className="img-fluid rounded-circle"
              />
            </Tilt>
            <h1 className="text-light">
              <a href="index.html">Sachin Suthar</a>
            </h1>
            <label className="label-popup">
              Project Leader | Full stack developer | DevOps specialist | GCP
              Engineer | AWS architect
            </label>

            <div className="social-links mt-4 text-center d-flex">
              <Tilt className="w-full ml-1">
                <a
                  href="skype:7046494456?call"
                  className="skype"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="bx bxl-skype"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="skype"
                  ></i>
                </a>
              </Tilt>

              <Tilt className="w-full ml-1">
                <a
                  href="https://github.com/sutharmsachin"
                  className="github"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="bx bxl-github"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Github"
                  ></i>
                </a>
              </Tilt>

              <Tilt className="w-full ml-1">
                <a
                  href="https://www.linkedin.com/in/sachin-suthar"
                  className="linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="bx bxl-linkedin"
                    data-tooltip-id="tooltip"
                    data-tooltip-content="Linkedin"
                  ></i>
                </a>
              </Tilt>

              <Tilt className="w-full ml-1">
                <a
                  href="https://www.upwork.com/freelancers/sachinsuthar"
                  className="upwork"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Upwork"
                ></a>
              </Tilt>
            </div>
            <Tooltip
              id="tooltip"
              place="top"
              effect="float"
              style={{
                backgroundColor: "rgb(255, 255, 255)",
                color: "#000000",
              }}
            />
          </div>

          <nav id="navbar" className="nav-menu navbar">
            <ul>
              <li>
                <a href="#hero" className="nav-link scrollto active">
                  <i className="bx bx-home"></i> <span>Home</span>
                </a>
              </li>
              <li>
                <a href="#about" className="nav-link scrollto">
                  <i className="bx bx-user"></i> <span>About</span>
                </a>
              </li>
              <li>
                <a href="#resume" className="nav-link scrollto">
                  <i className="bx bx-file-blank"></i> <span>Resume</span>
                </a>
              </li>
              <li>
                <a href="#portfolio" className="nav-link scrollto">
                  <i className="bx bx-book-content"></i> <span>Portfolio</span>
                </a>
              </li>
              <li>
                <a href="#services" className="nav-link scrollto">
                  <i className="bx bx-server"></i> <span>Services</span>
                </a>
              </li>
              <li>
                <a href="#contact" className="nav-link scrollto">
                  <i className="bx bx-envelope"></i> <span>Contact</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      {/* End Header */}
    </>
  );
};

export default Header;
