import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the default CSS

const IQuick = () => {
  // The list of images to display in the gallery
  const images = [
    {
      original: "assets/img/portfolio/Iquick/IquickWeb.png",
      thumbnail: "assets/img/portfolio/Iquick/IquickWeb.png",
      originalClass: "slider-image",
      thumbnailClass: "slider-thumbnail",
    },
    {
      original: "assets/img/portfolio/Iquick/Iquick2.png",
      thumbnail: "assets/img/portfolio/Iquick/Iquick2.png",
      originalClass: "slider-image",
      thumbnailClass: "slider-thumbnail",
    },
    {
      original: "assets/img/portfolio/Iquick/Iquick3.png",
      thumbnail: "assets/img/portfolio/Iquick/Iquick3.png",
      originalClass: "slider-image",
      thumbnailClass: "slider-thumbnail",
    },

    //
  ];
  // State to track whether the image slider modal is open or not
  const [isSliderOpen, setIsSliderOpen] = useState(false);

  // Function to handle image click and open the slider modal
  const handleImageClick = () => {
    setIsSliderOpen(true);
  };

  // Function to close the slider modal
  const handleCloseSlider = () => {
    setIsSliderOpen(false);
  };

  return (
    <div id="portfolio" className="portfolio div-bg">
      <div>
        <img
          src={images[0].thumbnail}
          alt="Main"
          onClick={handleImageClick}
          style={{
            cursor: "pointer",
            borderRadius: "0.5rem 0.5rem 0 0",
            objectFit: "contain",
            // aspectRatio: "4/3",
            width: "300px",
            maxHeight: "max(20rem, 30vh)",
          }}
        />
      </div>

      {/* Image Slider */}
      {isSliderOpen && (
        <div
          className="slider-container"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <span
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              fontSize: 20,
              cursor: "pointer",
              color: "#fff",
            }}
            onClick={handleCloseSlider}
          >
            &times;
          </span>
          <div style={{ maxWidth: "80%", maxHeight: "80%" }}>
            <ImageGallery
              items={images}
              showThumbnails={false} // Hide default thumbnails
              renderLeftNav={(onClick) => (
                <button
                  type="button"
                  onClick={onClick}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: 10,
                    transform: "translateY(-50%)",
                    zIndex: 1000,
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  &lt;
                </button>
              )}
              renderRightNav={(onClick) => (
                <button
                  type="button"
                  onClick={onClick}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: 10,
                    transform: "translateY(-50%)",
                    zIndex: 1000,
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  &gt;
                </button>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default IQuick;
