import React from "react";

const Services = () => {
  return (
    <>
      <section id="services" className="services">
        <div className="container">
          <div className="section-title">
            <h2>Services</h2>
            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
              <div className="icon">
                <i className="bi bi-code-square"></i>
              </div>
              <h4 className="title">
                <a href="/"> Application Development</a>
              </h4>
              <p className="description">
                As a trusted program delivery partner, I have consistently
                achieved a remarkable 96% on-time delivery record and maintained
                a defect density ratio 2.5 times lower than the industry
                standards outlined by the Capers Jones quality metrics.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon">
                <i className="bi bi-palette"></i>
              </div>
              <h4 className="title">
                <a href="/"> Custom Web Design</a>
              </h4>
              <p className="description">
                I possess the skills, understanding, knowledge, talent, and
                experience required to create cutting-edge visuals for web
                design and wireframes.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="icon">
                <i className="bi bi-gear"></i>
              </div>
              <h4 className="title">
                <a href="/">Software Development</a>
              </h4>
              <p className="description">
                As an affiliated enterprise with a software firm, I consistently
                achieve success through a delegated approach, unwavering
                commitment, and dedicated efforts.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="icon">
                <i className="bi bi-check-square"></i>
              </div>
              <h4 className="title">
                <a href="/"> Quality Assurance</a>
              </h4>
              <p className="description">
                I specialize in providing digital enterprise customers with an
                enhanced end-user experience, achieved through the provision of
                reliable software testing and quality assurance services, all
                underpinned by innovative solutions.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="icon">
                <i className="bi bi-life-preserver"></i>
              </div>
              <h4 className="title">
                <a href="/">Application Support</a>
              </h4>
              <p className="description">
                I enhance the customer experience by optimizing software
                performance and aligning IT deliverables with business goals.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-6 icon-box"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="icon">
                <i className="bi bi-receipt"></i>
              </div>
              <h4 className="title">
                <a href="/">Ecommerce Services</a>
              </h4>
              <p className="description">
                I excel at creating websites, but I am specialize in turning
                e-commerce sites into profitable ventures, transforming your
                investment into a stage for earnings.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
